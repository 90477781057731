import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import DefaultHero from "../components/common/default-hero"
import StartPageTeasers from "../components/pages/start-page/start-page-teasers"
import StartPageNews from "../components/pages/start-page/start-page-news"
import WelcomeSection from "../components/pages/start-page/welcome-section"
import { graphql } from "gatsby"
import { Media } from "../media"

export const query = graphql`
  {
    prismic {
      allContacts {
        edges {
          node {
            title
            contact_fields {
              icon
              information
            }
          }
        }
      }
    }
    prismic {
      allHomePages {
        edges {
          node {
            divider_image
            divider_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            divider_text
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            hero_information
            hero_title
            teaser {
              image
              link
              text
              imageSharp {
                childImageSharp {
                  fixed(width: 300, height: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    prismic {
      allNews(first: 20, sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            title
            text
            _meta {
              id
              firstPublicationDate
            }
          }
        }
      }
    }
  }
`
const IndexPage = ({ data }) => {
  const homePage = data.prismic.allHomePages.edges[0].node
  const news = data.prismic.allNews.edges
  return (
    <Layout>
      <SEO title='Hem' />
      <DefaultHero
        heroImg={homePage.hero_background_imageSharp.childImageSharp.fluid}
        heroTitle={homePage.hero_title}
        heroInformation={homePage.hero_information}
        moving={true}
      />
      <Media greaterThan="md">
        <StartPageTeasers teasers={homePage.teaser} />
      </Media>
      <WelcomeSection img={homePage.divider_imageSharp.childImageSharp.fluid} />
      <StartPageNews news={news} />
    </Layout>
  )
}

export default IndexPage
