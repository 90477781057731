import React, { useEffect, useState } from "react"
import Teaser from "./components/teaser"
import { DEFAULT_BLUE } from "../../../../styles/common"
import HoverLine from "../../../common/hover-line/dist"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem;
`
const TitleWrapper = styled.div`
  width: 30rem;
  text-align: center;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`
const TeaserRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100vw;
`

const StartPageTeasers = ({teasers}) => {
  const [teaserRows, setTeaserRows] = useState([])


  useEffect(() => {
    if (teasers) {
      let rows = []
      let tick = 0
      let invert = false
      teasers.forEach(teaser => {
        if (tick === 2) {
          tick = 0
          invert = !invert
        }
        if (tick === 0) {
          rows.push({ invert: invert, teasers: [] })
        }
        let currentRow = rows[rows.length - 1]
        currentRow.teasers.push(teaser)
        tick++
      })
      setTeaserRows(rows)
    }
  }, [])
  const renderTeaserRow = (teaserRow, index) => (
    <TeaserRowWrapper key={index}>
      {teaserRow.teasers.map((teaser, index) => (
        <Teaser
          key={index}
          invert={teaserRow.invert}
          text={teaser.text}
          fixedImg={teaser.imageSharp.childImageSharp.fixed}
          link={teaser.link}
        />
      ))}
    </TeaserRowWrapper>
  )
  return (
    <Wrapper>
      <TitleWrapper>
        <HoverLine show color={DEFAULT_BLUE} position='relative' size='0.1' />
      </TitleWrapper>
      {teaserRows.length &&
        teaserRows.map((teaserRow, index) => renderTeaserRow(teaserRow, index))}
    </Wrapper>
  )
}

export default StartPageTeasers
