import React from "react"
import Section from "../../../common/section"
import HoverLine from "../../../common/hover-line"
import FullWidthView from "../../../common/full-width-view"
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 45rem;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 11rem;
  text-align: center;
`

const HoverlineWrapper = styled.div`
  width: 30rem;
`

const WelcomeSection = ({ img }) => {
  return (
    <Section>
      <Wrapper>
        <TextWrapper>
          <h2>
            <i>
              Välkommen till Göteborgs Södra Skärgårds mysigaste bad &
              bastuställe!
            </i>
          </h2>
          <HoverlineWrapper>
            <HoverLine show size='0.1' />
          </HoverlineWrapper>
        </TextWrapper>
        <FullWidthView fluidImg={img}/>
      </Wrapper>
    </Section>
  )
}

export default WelcomeSection
