import React from "react"
import { DEFAULT_BLUE } from "../../../../../../styles/common"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs"
import ReadMoreButton from "../../../../../common/read-more-button"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100vw;
  color: white;
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25rem;
  margin: 0.1rem;
  background-color: ${DEFAULT_BLUE};
  text-align: center;
  height: 20rem;
`

const ImageWrapper = styled.div`
  width: 30rem;
  margin: 0.1rem;
  height: 20rem;
`

const Teaser = ({ invert, fixedImg, text, link }) => {

  return (
    <Wrapper style={{ flexDirection: invert ? "row-reverse" : "row" }}>
      <TextWrapper>
        {RichText.render(text)}
        <ReadMoreButton marginLeft='16rem' marginTop='15rem' to={link ? RichText.asText(link) : "#"} />
      </TextWrapper>
      <ImageWrapper>
        <Img fixed={fixedImg}></Img>
      </ImageWrapper>
    </Wrapper>
  )
}

export default Teaser
