import React from "react"
import LatestNews from "./components/latest-news"
import FeaturedNews from "./components/featured-news"
import { useStaticQuery, graphql } from "gatsby"
import { DEFAULT_MAX_WIDTH } from "../../../../styles/common"
import styled from "styled-components"
import { Media } from "../../../../media"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 10rem;
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${DEFAULT_MAX_WIDTH};
  justify-content: space-between;
  align-items: flex-start;
`

const StartPageNews = ({ news }) => {

  return (
    <Wrapper>
      <InnerWrapper>
        <LatestNews news={news[0]} />
        <Media greaterThan='sm'>
          <FeaturedNews news={news} />
        </Media>
      </InnerWrapper>
    </Wrapper>
  )
}

export default StartPageNews
