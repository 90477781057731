import React, { useState } from "react"
import HoverLine from "../hover-line"
import Icon from "@material-ui/core/Icon"
import { Link } from "gatsby"
const ReadMoreButton = ({
  position = "absolute",
  marginLeft = 0,
  marginTop = 0,
  color = "white",
  hoverColor = "white",
  to
}) => {
  const [hoverReadMore, setHoverReadMore] = useState(false)

  const readMoreWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    position: position,
    marginLeft: marginLeft,
    marginTop: marginTop,
    cursor: hoverReadMore ? "pointer" : "default",
  }

  const readMoreLabelStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    color: color,
    width: 'fit-content'
  }
  return (
    <div
      style={readMoreWrapperStyle}
      onMouseEnter={() => setHoverReadMore(true)}
      onMouseLeave={() => setHoverReadMore(false)}
    >
      <div style={readMoreLabelStyle}>
        <HoverLine
          show={hoverReadMore}
          position='relative'
          color={hoverColor}
          size='0.1'
        />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Link style={{ margin: "0", color: color, fontSize: '1.4rem' }} to={to}>Läs mer</Link>
          <Icon>navigate_next</Icon>
        </div>
        <HoverLine
          show={hoverReadMore}
          position='relative'
          color={hoverColor}
          bottom
          size='0.1'
          bottom
          invert
        />
      </div>
    </div>
  )
}

export default ReadMoreButton
