import React from "react"
import HoverLine from "../../../../../common/hover-line/dist"
import {
  DEFAULT_BLUE,
  DEFAULT_BLUE_90,
} from "../../../../../../styles/common"
import { RichText } from "prismic-reactjs"
import styled from 'styled-components'
import moment from "moment"

const Wrapper = styled.div`
  text-align: left;
  padding-left: 5rem;
  margin-right: 5rem;
  max-width: 85vw;
`

const Date = styled.p`
  bottom: 1rem;
  right: 1rem;
  text-align: right;
  font-size: 2.0rem;
`

const LatestNews = ({ news }) => {

  return (
    <Wrapper>
      <div>
        <h1 style={{ color: DEFAULT_BLUE }}>Senaste Nytt</h1>
        <div style={{width: '45rem', maxWidth: '85vw'}}>
          <HoverLine show color={DEFAULT_BLUE} bottom position="relative" />
        </div>
      </div>
      <div style={{ paddingTop: "2rem", maxWidth: '55rem' }}>
        <h3 style={{ color: DEFAULT_BLUE_90 }}>
          {RichText.asText(news.node.title)}
        </h3>
        <div>{RichText.render(news.node.text)}</div>
      </div>
      <Date>{moment(news.node._meta.firstPublicationDate).format('YYYY-MM-DD')}</Date>
    </Wrapper>
  )
}

export default LatestNews
