import React from 'react'

const Section = ({children}) => {
  const wrapperStyle = {
    width: '100vw'
  }
  return (
    <div style={wrapperStyle}>
      {children}
    </div>
  )
}

export default Section