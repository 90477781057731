import React from "react"
import HoverLine from "../../../../../common/hover-line/dist"
import { DEFAULT_BLUE_90, DEFAULT_BLUE } from "../../../../../../styles/common"
import { RichText } from "prismic-reactjs"
import ReadMoreButton from "../../../../../common/read-more-button"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 22vw;
  background-color: ${DEFAULT_BLUE_90};
  padding: 2rem;
  overflow-y: scroll;
  max-height: 1000px;
`

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const TitleWrapper = styled.div`
  width: 70%;
  color: white;
  margin-bottom: 1rem;
`
const Title = styled.h2`
  margin: 0;
`

const TextWrapper = styled.div`
  width: 70%;
  margintop: 2rem;
  padding: 2rem;
  background-color: #fff7f7;
  border-radius: 3px;
  font-size: 1.3rem;
`

const FeaturedNews = ({ news }) => {
  const renderNews = (newsNode, i) => {
    const title = RichText.asText(newsNode.node.title)
    const text = RichText.asText(newsNode.node.text)
    return (
      <NewsWrapper key={newsNode.node._meta.id}>
        <TitleWrapper>
          <Title>
            {title.substring(0, 20) + (title.length > 20 ? "..." : "")}
          </Title>
          <HoverLine show color="white" bottom position="relative" size="0.1" />
        </TitleWrapper>
        <TextWrapper>
          {text.substring(0, 200) + (text.length >= 200 ? "......" : "")}
          <ReadMoreButton
            position="relative"
            marginTop="2rem"
            hoverColor={DEFAULT_BLUE}
            color="black"
            to={`/nyheter?selected=${i}`}
          />
        </TextWrapper>
      </NewsWrapper>
    )
  }
  return <Wrapper>{news.map((newsNode, i) => renderNews(newsNode, i))}</Wrapper>
}

export default FeaturedNews
